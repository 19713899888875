<template>
  <body-layout :title="title">
    <div class="actions flex" slot="actions" > <!-- v-if="!isPreview" -->
      <a-button
        type="default"
        class="mr-3"
        icon="copy"
        @click="batchCopy"
        v-if="selectedRowKeys.length"
        v-has="'system:rule:list:button:add'"
      >
        创建副本
      </a-button>
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length>=2||!(selectionRows.length&&selectionRows[0].departments&&selectionRows[0].departments.length)"
        icon="cloud"
        v-has="'system:rule:list:button:add'"
      >同步</a-button>
      <a-button
        type="danger"
        icon="delete"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        v-has="'system:rule:list:button:delete'"
        >删除</a-button
      >

      <a-button type="primary" @click="openAddForm" icon="plus" v-has="'system:rule:list:button:add'">新建</a-button>
    </div>

    <!-- table区域-begin -->
    <div>

      <!-- <a-table :scroll="{ x: 800, y: 500 }" v-has="'system:rule:list:page:view'" -->
      <!-- <a-table ref="table" style="border-top:1px solid rgb(232,232,232)" size="middle" rowKey="id" :columns="columns"
        :dataSource="dataSource" :customRow="clickRow" :pagination="ipagination" :loading="loading" :rowSelection="{
          hideDefaultSelections: isPreview,
          selectedRowKeys: selectedRowKeys,
          onChange: isPreview ? onPreviewSelectChange : onSelectChange,
        }" :scroll="{ x: 'max-content', y: 'calc(100vh - 323px)' }" @change="handleTableChange">
        <span slot="targetType" slot-scope="text, record">
          <div v-if="isTaskSelect(record.ruleType)">
            <a-tag v-for="one in record.stages" :key="one.id">
              {{ getStageName(one.stageName, record.ruleType) }}
            </a-tag>
          </div>
          <div v-else-if="record.ruleType == 'LEVEL'">
            <a-tag v-for="one in record.members" :key="one.positionId">
              {{ '职位-' + one.positionName }}
            </a-tag>
          </div>
        </span>
        <span slot="ruleType" slot-scope="text, record">
          <label>{{ getRuleType(record.ruleType) }}</label>
        </span>

        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">
            {{ one.departName }}
          </a-tag>
        </span>

        <span slot="positionNames" slot-scope="text, record">
          <a-tag v-for="one in record.positions" :key="one.positionId">
            {{ one.positionName }}
          </a-tag>
        </span>
      </a-table> -->
      <v-table
        ref="newTable"
        :queryParamProp="queryParam"
        :selectedRowKeysprop="selectedRowKeys"
        :url="url"
        @openModal="openModal"
        :columns="columns"
        @setRowkeys="setRowkeys"
        :isPreview="false"
      />
    </div>
    <!-- table区域-end -->
    <rule-modal
      :is-preview="false"
      :isMaskClosable="isMaskClosable"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
      :props-filters="propsFilters"
      :hide-edit-btn="false"
      @onConfirm="onConfirm"
    ></rule-modal>
    <sync-modal ref="syncModal" url="/rule/sync" :selectedRowKeys="selectedRowKeys" @loadData="reLoadData" :selectionRows="selectionRows" />
  </body-layout>
</template>

<script>
import RuleModal from './modules/rule/rule-modal'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import syncModal from '@/components/sync-modal'
import JInput from '@/components/jeecg/JInput'
import { createRuleTypeList } from '@/enums/ruleTypeList'
import vTable from '@/components/v-table/vTable'
export default {
  name: 'RuleParamterList',
  mixins: [JeecgListMixin],
  components: {
    RuleModal,
    syncModal,
    JInput,
    vTable
  },
  props: {
    hideEditBtn: {
      type: Boolean,
      default: () => false
    },
    isPreview: {
      type: Boolean,
      default: () => false
    },
    isMaskClosable: {
      type: Boolean,
      default: () => false
    },
    hideEditBtn: {
      type: Boolean,
      default: () => false
    },
    title: '',
    propsFilters: {
      type: Object,
      default: () => ({})
    },
    propsIpagination: {
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
      showTotal: (total, range) => {
        return range[0] + '-' + range[1] + ' 共' + total + '条'
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: 0
    }
  },
  data() {
    return {
      description: '规则参数界面',
      queryParam: { domain: 'GLOBAL' },
      recycleBinVisible: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 100,
          checked: true,
          isNum: false
        },
        {
          title: '名称',
          dataIndex: 'name',
          checked: true,
          isNum: false
        },
        {
          title: '规则类型',
          dataIndex: 'ruleType',
          scopedSlots: { customRender: 'ruleType' },
          checked: true,
          isNum: false
        },
        {
          title: '适用对象',
          dataIndex: 'targetType',
          scopedSlots: { customRender: 'targetType' },
          checked: true,
          width: 300,
          isNum: false
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 300,
          checked: true,
          isNum: false
        },
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions',
          checked: true,
          isNum: false
        }
      ],
      url: {
        list: '/rule/list',
        delete: '/rule/delete',
        deleteBatch: '/rule/delete_batch',
        copyBatch: '/rule/copy_batch',
      },
      ruleTypeList: {},
      disableMixinCreated: true
    }
  },
  computed: {},
  methods: {
    reLoadData(){
      if(this.$refs.newTable) this.$refs.newTable.loadData()
    },
    setRowkeys(records) {
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
    },
    openModal(record) {
      this.openDetail(record)
    },
    getStageName(stageName, ruleType) {
      let rule = this.ruleTypeList[ruleType] ? this.ruleTypeList[ruleType].label : ''
      switch (rule) {
        case '考核规则':
          stageName = '阶段-' + stageName
          break
        case '价格规则':
          stageName = '阶段-' + stageName
          break
        case '等级规则':
          stageName = '阶段-' + stageName
          break
        case '单拎系数规则':
          stageName = '阶段-' + stageName
          break
        case '难度系数规则':
          stageName = '阶段-' + stageName
          break
        case '考核得分系数规则':
          stageName = '阶段-' + stageName
          break
        case '系数规则':
          stageName = '阶段-' + stageName
          break
      }
      return stageName
    },
    isTaskSelect(ruleType) {
      return ['KPI', 'FACTOR', 'MONEY', 'DIFFICULTLY', 'KPI_SCORE', 'COEFFICIENT','NOT_LOCAL'].includes(ruleType)
    },
    getRuleType(type) {
      return this.ruleTypeList[type] ? this.ruleTypeList[type].label : ''
    },
    onPreviewSelectChange(selectedRowKeys, selectionRows) {
      console.log(selectedRowKeys, selectionRows, 'onPreviewSelectChange')
    },
    onConfirm(id) {
      console.log('views system rule onConfirm', { id })
      this.selectedRowKeys = [...this.selectedRowKeys, id]
      console.log('onConfirm', { id, 'this.dataSource': this.dataSource, 'this.selectedRowKeys': this.selectedRowKeys })
      let isAllConfirm = this.dataSource.map(({ id }) => id).every(id => this.selectedRowKeys.includes(id))
      if (isAllConfirm) {
        console.log('全部勾选了!!!')
        this.$emit('tableConfirm', this.propsFilters)
      }
    }
  },
  created() {
    this.ruleTypeList = createRuleTypeList()
    if (this.propsFilters.typeId) {
      this.columns = this.columns.filter(({ dataIndex }) => dataIndex != 'typeId')
    }
    this.filters = { ...this.filters, ...this.propsFilters }
    this.loadData()
    //初始化字典配置 在自己页面定义
    this.initDictConfig()
  }
}
</script>
<style lang="less" scoped>
@import '~@assets/less/common.less';

/deep/ .border-bottom {
  border: none;
}
</style>
